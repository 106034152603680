.accordion .accordion .accordion-button {
    padding: 0.5rem;
}

.accordion-header {
    &.hide-button {
        .accordion-button::after {
            display: none;
        }
    }
}

.accordion-item .presenter-item-icons {
    min-width: 40px;
    width: 40px;

    i.material-icons {
        &:last-child:not(:only-child) {
            margin-top: 16px;
        }
    }
}

.empty-accordion > button {
    &::after {
        display: none
    }
}